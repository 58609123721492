import { render, staticRenderFns } from "./DatePickerNode.vue?vue&type=template&id=8f0b04ce&scoped=true&"
import script from "./DatePickerNode.vue?vue&type=script&lang=js&"
export * from "./DatePickerNode.vue?vue&type=script&lang=js&"
import style0 from "./DatePickerNode.vue?vue&type=style&index=0&id=8f0b04ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f0b04ce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/nde/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8f0b04ce')) {
      api.createRecord('8f0b04ce', component.options)
    } else {
      api.reload('8f0b04ce', component.options)
    }
    module.hot.accept("./DatePickerNode.vue?vue&type=template&id=8f0b04ce&scoped=true&", function () {
      api.rerender('8f0b04ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/DatePicker/Node/DatePickerNode.vue"
export default component.exports